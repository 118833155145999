/* eslint-disable prefer-object-spread */
import { createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../../data/axiosInstance/axiosInstance'; 
import executor from   '../../../data/axiosInstance/executor';

const API_URL = `${process.env.REACT_APP_API_SINISTRI_URL}`;
 
const initialState = {
  checkList: []
};

export const CheckListSlice = createSlice({
  name: 'checkList',
  initialState,
  reducers: {
    getCheckList: (state, action) => {
      state.checkList = action.payload;
    }
  }
});

export const { getCheckList } = CheckListSlice.actions;

export const fetchCheckList = () => async (dispatch) => {   
  dispatch(getCheckList([]));

  const API_URI_CALL = `${API_URL}/api/v1/bff/esiti`;
  const fetchData = async  () => axiosInstance.get(API_URI_CALL);
  const [res, err] = await executor.executeAsync(fetchData);

  //console.log('res',res); 
  console.log('err',err);
   
  return dispatch(getCheckList(res.data));   
}; 

export default CheckListSlice.reducer;
