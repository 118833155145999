import axios from "axios";

const API_URL = `${process.env.REACT_APP_API_OVERX_URL}/api/v1/sicurezza/`;

const login = (username, password) => {
  return axios
    .post(`${API_URL}login`, {
      username,
      password,
    })
    .then((response) => {
      if (response.data.access_token) {
         localStorage.setItem("user", JSON.stringify(response.data));
      }
      return response.data;
    }).catch(e => {
      console.log("Err on loginAuth ",e);
      return e;
    }); 
}

const logout = () => { // this is shit
  localStorage.removeItem("user");
  localStorage.setItem("message", "");
  window.location.reload();
};

const getCurrentTokenUser = () => { 
  const user = JSON.parse(localStorage.getItem("user"));
  if (user && user.accessToken) {
    return JSON.parse(localStorage.getItem("user"));
  }   
  return JSON.parse('{"user":"nouser"}');    
};

const refreshToken = async () => {    
  const refreshTokenOld = JSON.parse(localStorage.getItem("user")).refresh_token;
  return axios
  .post(`${API_URL}refresh-token`, { "refresh_token" : refreshTokenOld
    })
    .then((response) => {
      if (response.data.access_token) {
         localStorage.setItem("user", JSON.stringify(response.data));}

      return response.data;
    }) .catch(error => {
      if (error.response.status === 400 || error.response.status === 401) {
        window.location.href = '/home'; // Naviga alla pagina di login
        throw error;
      }
      
  });
};

const AuthService = {
  login,
  logout,
  getCurrentTokenUser,
  refreshToken,
};

export default AuthService;