/* eslint-disable prefer-object-spread */
import { createSlice } from '@reduxjs/toolkit'; 
import axiosInstance from '../../../data/axiosInstance/axiosInstance'; 
import executor from   '../../../data/axiosInstance/executor';

const API_URL = `${process.env.REACT_APP_API_SINISTRI_URL}`; 
 
const initialState = {
  mutliValutapolizza: [],
  actualValuta: ""
};

/**
 * Dollaro(valuta scelta dall'utente) verso valuta di polizza
 */

/*
const valuteEUR = [
  {
    "nazione": "UNIONE MONETARIA EUROPEA",
    "descrizione": "Euro",
    "codice": "EUR",
    "tassoCambio": "1"
  },
  {
    "nazione": "Stati Uniti",
    "descrizione": "Dollaro USA",
    "codice": "USD",
    "tassoCambio": "0.93063"
  },
  {
    "nazione": "Regno Unito",
    "descrizione": "Sterlina Gran Bretagna",
    "codice": "GBP",
    "tassoCambio": "1.17423"
  },
  {
    "nazione": "Giappone",
    "descrizione": "Yen Giapponese",
    "codice": "JPY",
    "tassoCambio": "0.00620"
  },
  {
    "nazione": "ALBANIA",
    "descrizione": "Lek",
    "codice": "ALL",
    "tassoCambio": "0.00950"
  }
]

const valuteUSD = [
  {
    "nazione": "UNIONE MONETARIA EUROPEA",
    "descrizione": "Euro",
    "codice": "EUR",
    "tassoCambio": "1.0798"
  },
  {
    "nazione": "Stati Uniti",
    "descrizione": "Dollaro USA",
    "codice": "USD",
    "tassoCambio": "1"
  },
  {
    "nazione": "Regno Unito",
    "descrizione": "Sterlina Gran Bretagna",
    "codice": "GBP",
    "tassoCambio": "1.261"
  },
  {
    "nazione": "Giappone",
    "descrizione": "Yen Giapponese",
    "codice": "JPY",
    "tassoCambio": "0.0067"
  },
  {
    "nazione": "ALBANIA",
    "descrizione": "Lek",
    "codice": "ALL",
    "tassoCambio": "0.217"
  }
]

const valuteGBP = [
  {
    "nazione": "UNIONE MONETARIA EUROPEA",
    "descrizione": "Euro",
    "codice": "EUR",
    "tassoCambio": "0.9262"
  },
  {
    "nazione": "Stati Uniti",
    "descrizione": "Dollaro USA",
    "codice": "USD",
    "tassoCambio": "0.793"
  },
  {
    "nazione": "Regno Unito",
    "descrizione": "Sterlina Gran Bretagna",
    "codice": "GBP",
    "tassoCambio": "1"
  },
  {
    "nazione": "Giappone",
    "descrizione": "Yen Giapponese",
    "codice": "JPY",
    "tassoCambio": "0.00620"
  },
  {
    "nazione": "ALBANIA",
    "descrizione": "Lek",
    "codice": "ALL",
    "tassoCambio": "0.00950"
  }
]

*/

export const MultiValutaSlice = createSlice({
  name: 'multiValuta',
  initialState,
  reducers: {
    getMultiValutaPolizza: (state, action) => {
      state.mutliValutapolizza = action.payload;
    },
    setActualValuta: (state, action) => {
      state.actualValuta = action.payload;
    }
  }
});

export const { getMultiValutaPolizza, setActualValuta } = MultiValutaSlice.actions;

export const fetchMultiValutaPolizza = (valutaPolizza, actualValutaPolizza) => async (dispatch) => {
  
  if (actualValutaPolizza!== valutaPolizza)
    {  dispatch(getMultiValutaPolizza([]));

      const API_URI_CALL = `${API_URL}/api/v1/bff/tassoCambio/multivaluta/${valutaPolizza}`;
      const fetchData = async  () => axiosInstance.get(API_URI_CALL);
      const [res, err] = await executor.executeAsync(fetchData);
      console.log('res',res); 
      console.log('err',err); 
      dispatch(setActualValuta(valutaPolizza));      
      return dispatch(getMultiValutaPolizza(res.data));

      /*
      switch (valutaPolizza) {
        case "EUR":
          return dispatch(getMultiValutaPolizza(valuteEUR));   
          
        case "GBP":
          return dispatch(getMultiValutaPolizza(valuteGBP));   
      
        case "USD":
          return dispatch(getMultiValutaPolizza(valuteUSD));   
      
        default:
          return dispatch(getMultiValutaPolizza(valuteEUR));   
        }
      */
    }
    return null; 
  }; 

export default MultiValutaSlice.reducer;
