const execute = (callback) => {
    try {
      const res = callback();
      return [res, null];
    } catch (err) {
      console.error(Error(err.message ?? err));
      // You can also log error messages to an error reporting service here
      return ["Execute sync error", err];
    }
  }; 

const executeAsync = async (callback) => {
    try {
      const res = await callback();
      return [res, null];
    } catch (err) {
      console.error(Error(err.message ?? err));
      // You can also log error messages to an error reporting service here
      return ["Execute async error", err];
    }
  };

  const executor = {
    execute,
    executeAsync,     
  };
  
  export default executor;