import { useEffect, useRef } from 'react';

const useEffectOnChange = (callback, dependencies) => {
  const initialRender = useRef(true);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      callback();
    }
  }, dependencies);
};

export default useEffectOnChange;
