/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
//import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import { store } from './store/Store';
import App from './App';

//import mock from './data/mock';
/*
import './data/notes/NotesData';
import './data/chat/Chatdata';
import './data/contacts/ContactsData';
import './data/email/EmailData';
import './data/ticket/TicketData';
import './data/singleTicket/SingleTicketData';
 */

//mock.onAny().passThrough();

const container = document.getElementById('root');
const root = createRoot(container);
require('dotenv').config();

root.render(
  
 
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  
   
);

// <React.StrictMode> </React.StrictMode> If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
