/* eslint-disable prefer-object-spread */
import { createSlice } from '@reduxjs/toolkit';
import jwtDecode from 'jwt-decode';
import axiosInstance from '../../../data/axiosInstance/axiosInstance'; 
import executor from   '../../../data/axiosInstance/executor';

const API_URL = `${process.env.REACT_APP_API_SINISTRI_URL}`;
 
const initialState = {
	riserveAuthority: {
		riservaIndennizzo: 3000000000,
		riservaSpese: 3000000000,
		utente: 'DEFAULT'
    }
};

export const RiserveAuthoritySlice = createSlice({
  name: 'riserveAuthority',
  initialState,
  reducers: {
	getRiserveAuthority: (state, action) => {
		state.riserveAuthority = action.payload;
	  }
  }
});

export const { getRiserveAuthority} = RiserveAuthoritySlice.actions;

export const fetchRiserveAuthority = () => async (dispatch) => {
	const user = JSON.parse(localStorage.getItem('user'));
	let decoded = {};
	if (user) {
	  decoded = jwtDecode(user.access_token);
	  const API_URI_CALL = `${API_URL}/api/v1/bff/validazioni/autorizzazione-riserva/${decoded.email}`;
	  const fetchData = async () => axiosInstance.get(API_URI_CALL);
  
	  const [res, err] = await executor.executeAsync(fetchData);
  
	  console.log('err', err);
	  if (err) return dispatch(getRiserveAuthority(initialState)); 
  
	  return dispatch(getRiserveAuthority(res.data));
	}
	return null;
  };
export default RiserveAuthoritySlice.reducer;
