import React from 'react';
import { useRoutes} from 'react-router-dom';
import {ErrorBoundary} from 'react-error-boundary'
import Themeroutes from "./routes/Router";
import './assets/scss/style.scss';
import ToastContainer from './components/toasts/ToastContainer';

/*    Handle exception version boundary */
import ErrorGeneric from './views/ErrorGeneric';

function ErrorFallback({error}) {
  return (
   <ErrorGeneric code={error.message} title={error.message} message={error.message} />
  )
}

function App() {
  const routing = useRoutes(Themeroutes);
 // const toaster = React.createRef();
  
  return (
    <div className="App">
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        {routing}
      </ErrorBoundary>

      <ToastContainer   />
    </div>
  );
}

/* without err
function App() {
  const routing = useRoutes(Themeroutes);
  return (
    <div className="App">
        {routing}
    </div>
  );
}*/

export default App;
