import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchTicket,fetchListaDanneggiati,fetchListaGaranzieDanneggiato, fetchRiserveComplessive, setListaSoggettiCausa, fetchListaIncaricatoNoQuadro, fetchRiserveSpeseIncarico, addIncaricato, setMotivazioneIncarico, setMotivazioneIncaricoNoQuadro, fetchCauseIstruttoria, fetchIncarichiIstruttoria, getListaIncaricatoNoQuadro, fetchListaBeneficiariIndennizzo,  fetchListaBenRecuperoFranchigiaTable,  fetchValidazioniIstruttoria, fetchListaPagamenti, fetchListaAllegatiPagamentoIndennizzo, removeIdTicketInCharge, removeOpenedTicket, fetchListaGaranziePagamentoIndennizzo } from '../../store/app/ticket/TicketSlice';
import { addTaskToNav, removeTaskToNav } from '../../store/app/addNavTask/AddNavTaskSlice';
import { removeTotalItemMessageToast } from '../../store/app/ws/WsSlice';
import { fetchAllegati  } from '../../store/app/allegati/AllegatiSlice';
import { fetchEmails } from '../../store/apps/email/EmailSlice';
import { fetchNote} from '../../store/app/diarioTrattazione/NoteSlice';

import { /* toggleModalMassimaleGaranzie, */ toggleModaleSoggTerzoColl, EnabledDisabledStateTrueFalsePresaCarico/* toggleModaleSoggTerzoCollModifica */ } from '../../store/customizer/CustomizerSlice';


// Il component renderizza le informazioni nel toast
// e in funzione delle info ricevute dalla ws scatena 
// le operazioni di fetch
const ToastContent = (props) => {
    const dispatch = useDispatch();
    const requestIdFromProps = props.message;

    const totalRequestMessageToast = useSelector((state) => state.wsReducer.itemMessagesToast);
    const soggetti = useSelector((state) => state.ticketReducer.openedTicket.datiCausa?.listaSoggetti?.content)
    const index = totalRequestMessageToast.findIndex((messageToast) => messageToast.requestId === JSON.parse(requestIdFromProps).requestId);
    const localToast = totalRequestMessageToast[index];
    const openedTicketLocal = (useSelector((state) => state.ticketReducer.openedTicket));
    const idSinistro = openedTicketLocal.datiDenuncia?.idSinistro;
    const modOpenedTicketsLocal = (useSelector((state) => state.ticketReducer.allOpenedTicket));
    const index2 = modOpenedTicketsLocal.findIndex((taskItem) => taskItem.datiTask.idTask === openedTicketLocal?.datiTask?.idTask?.toString());  
    const delegaValue=modOpenedTicketsLocal[index2]?.datiPolizza?.coassicurazione?.tipoCoassicurazione==="NOSTRA_DELEGA" || modOpenedTicketsLocal[index]?.datiPolizza?.coassicurazione?.tipoCoassicurazione==="Nostra delega" ? modOpenedTicketsLocal[index]?.datiPolizza?.coassicurazione?.ripartizionePerc/100:1
    //const modalMassimaleGaranzie = useSelector((state) => state.customizer.isModalMassimaleGaranzieVisible);
    const taskIdInCharge = (useSelector((state) => state.ticketReducer.taskIdInCharge));
    
    // todo controllare local err nei file
    const handleAddTaskTab = (taskId) => {  
        //dispatch(fetchTicket( {taskId: localToast.taskId }));
        //dispatch(addTaskToNav({ taskId: localToast.taskId }));
        //dispatch(getCalcoloMassimali({}));
        //dispatch(fetchValidazioniIstruttoria(idSinistro));

        dispatch(fetchTicket( {taskId}));
        dispatch(addTaskToNav({taskId}));
    };

    const handleResult = () => {
        //here we process fecth if we need it
        if (localToast?.fetchToEval === "Allegati") dispatch(fetchAllegati(idSinistro));
        
        if (localToast?.fetchToEval === "Appuntamento")   dispatch(fetchNote(localToast.taskId));

        if (localToast?.fetchToEval === "Danneggiato"){
            //if (modalMassimaleGaranzie===true) dispatch(toggleModalMassimaleGaranzie());
            dispatch(fetchListaDanneggiati(idSinistro));
            dispatch(fetchListaGaranzieDanneggiato(localToast.taskId, localToast.danneggiatoId, localToast.ramoParams, localToast.idTitolo, localToast.idBene));
            dispatch(fetchRiserveComplessive(idSinistro,delegaValue));
            //dispatch(toggleIsButtonEnabledMassimaleGaranzieVisible(true));
        }

        if(localToast?.fetchToEval === "SoggettoTerzo"){
            dispatch(fetchListaDanneggiati(idSinistro));
            dispatch(toggleModaleSoggTerzoColl());
        }

        if(localToast?.fetchToEval === "SoggettoTerzoModificato"){
            dispatch(fetchListaDanneggiati(idSinistro));
            // localToast.loading()
            //dispatch(toggleModaleSoggTerzoCollModifica());
        }

        if(localToast?.fetchToEval === "Soggetto_Terzo_Eliminato"){
            dispatch(fetchListaDanneggiati(idSinistro));
            if(localToast?.modalElimina === true) localToast.setModalElimina()
            if(localToast?.loading) localToast.loading()
            //dispatch(toggleModaleSoggTerzoCollModifica());
        }

        if (localToast?.fetchToEval === "DanneggiatoUpdate"){
            localToast.toggle()
            dispatch(fetchListaDanneggiati(idSinistro));
            dispatch(fetchListaGaranzieDanneggiato(localToast.taskId, localToast.danneggiatoId, localToast.ramoParams, localToast.idTitolo, localToast.idBene));
            dispatch(fetchRiserveComplessive(idSinistro,delegaValue));
            //dispatch(toggleIsButtonEnabledMassimaleGaranzieVisible(true));
        }
        
        if (localToast?.fetchToEval === "Email") dispatch(fetchEmails(openedTicketLocal?.datiTask?.idTask));

        if (localToast?.fetchToEval === "Diario"){
            dispatch(fetchNote(localToast.taskId));
            if(localToast.valToUpdateDescr && localToast.valToUpdateNota ) localToast.updateDescrNota(localToast.valToUpdateDescr,localToast.valToUpdateNota);
        }

        if (localToast?.fetchToEval === "Soggetto") dispatch(setListaSoggettiCausa(soggetti === null)) ;

        if (localToast?.fetchToEval === "Incaricato"){
            dispatch(fetchListaIncaricatoNoQuadro(localToast.incaricatoId));
            dispatch(addIncaricato(localToast.objIncaricato));
            dispatch(setMotivazioneIncarico(null));
            dispatch(setMotivazioneIncaricoNoQuadro(localToast?.motivazione));
            localToast.setDataFiduciarioNoQuadro([localToast.objIncaricatoLista]) 
        }
        
        if (localToast?.fetchToEval === "Incaricato_Modificato") {
            localToast.setDataFiduciarioNoQuadro([localToast.objIncaricato]);
            dispatch(fetchListaIncaricatoNoQuadro(localToast.incaricatoId));
            dispatch(setMotivazioneIncaricoNoQuadro(localToast?.motivazione));
        }

        if (localToast?.fetchToEval === "Incaricato_Eliminato") {
            localToast.setDataFiduciarioNoQuadro([])
            dispatch(getListaIncaricatoNoQuadro([]))
        };
            
        if (localToast?.fetchToEval === "RiservaSpese") {
            dispatch(fetchRiserveComplessive(localToast.sinistroId,delegaValue));   
            dispatch(fetchRiserveSpeseIncarico(localToast.riservaSpesaId));
            dispatch(fetchValidazioniIstruttoria(localToast.sinistroId));
        }
        
        if (localToast?.fetchToEval === "Causa"){
            dispatch(fetchCauseIstruttoria(localToast.idSinistro));
        }
        
        if (localToast?.fetchToEval === "Incarico"){
            dispatch(fetchIncarichiIstruttoria(localToast.idSinistro));
        }

        //if(localToast?.fetchToEval === "Fiduciario") dispatch(fetchIncarichiIstruttoria(localToast.idSinistro));

        if(localToast?.fetchToEval === "Beneficiario") dispatch(fetchListaBeneficiariIndennizzo(localToast.pagamentoId));

        if(localToast?.fetchToEval === "Debitore") dispatch(fetchListaBenRecuperoFranchigiaTable(localToast.idRecupero));

        if(localToast?.fetchToEval === "PagamentoSpesa"){
            dispatch(fetchListaPagamenti(localToast.idSinistroPagSpese));
            dispatch(fetchIncarichiIstruttoria(localToast.idSinistroPagSpese));
            dispatch(fetchRiserveComplessive(localToast.idSinistroPagSpese,delegaValue))
        } 

        if(localToast?.fetchToEval === "PagamentoIndennizzo"){
            dispatch(fetchListaPagamenti(localToast.idSinistro));
            dispatch(fetchIncarichiIstruttoria(localToast.idSinistro));
            dispatch(fetchRiserveComplessive(localToast.idSinistro,delegaValue))
        }

        if(localToast?.fetchToEval === "documentoGenerato") {
            dispatch(fetchListaAllegatiPagamentoIndennizzo(localToast.idSinistro,"Quietanza"))
            dispatch(fetchAllegati(localToast.idSinistro))
        } 

        if(localToast?.fetchToEval === "Esitazione"){
            dispatch(fetchValidazioniIstruttoria(localToast?.idSinistro));
            dispatch(fetchIncarichiIstruttoria(localToast?.idSinistro));
            dispatch(fetchRiserveComplessive(localToast.idSinistro,delegaValue));
            //dispatch(setRiassegnaoperatoreSinistro(null));
            //dispatch(setOperatori(null));
        }

        if(localToast?.fetchToEval === "Riassegnazione sinistro"){
            const taskId = localToast?.taskId;

            dispatch(removeTaskToNav({ taskId }));
            dispatch(removeIdTicketInCharge(taskId)); //free frontend
            if (taskIdInCharge === `${taskId};`) {
                //se esistono altri ticket in modalità di modifica occorre lasciare invariato lo stato del customizer
                //diversamente andiamo in toggle
                dispatch(EnabledDisabledStateTrueFalsePresaCarico());
            }
            dispatch(removeOpenedTicket({ taskId })); //TicketReducer
        }

        if(localToast?.fetchToEval === "UpdateRiserva"){
            dispatch(fetchListaGaranziePagamentoIndennizzo(localToast?.valueSelect, localToast?.pagamentoId));
            dispatch(fetchRiserveComplessive(localToast?.idSinistro,localToast?.delegaValue));
        }
        
        //last but not least
        if (localToast?.loader) localToast.loader();
    }

    useEffect(() => {
        let  timeout = 2000;
        if (localToast?.navigate && JSON.parse(requestIdFromProps).operation.status!=='SUCCESS') timeout = 1500;
        
        handleResult();
        /*
        setTimeout(() => {
            dispatch(removeTotalItemMessageToast(localToast?.requestId));            
            //if (localToast?.fetchToEval === "Esitazione" && localToast.idTaskPadre !== undefined && localToast.idTaskPadre !== "") handleAddTaskTabFiglioToPadre(localToast?.idTaskPadre)
            let idLink = localToast?.taskId;
            if (localToast?.idTaskPadre !== undefined && localToast?.idTaskPadre !== "" && localToast?.navigate && localToast?.navigate2father) {
                //&& localToast?.navigate2father
                // eslint-disable-next-line eqeqeq  
                if (idLink != localToast?.idTaskPadre) {
                    idLink = localToast?.idTaskPadre;
                } else {
                    
                    localNavigate = false; 
                }
            } //else localNavigate = false;
            if (localToast?.navigate && localNavigate && !(localToast?.idTaskPadre === undefined || localToast?.idTaskPadre === "")) document.getElementById(idLink).click();
        }, timeout);*/

        //CArnoutMAP
        //localToast?.idTaskPadre !== undefined && localToast?.idTaskPadre !== "" >> è un task figlio
        //localToast?.navigate true && localToast?.navigate2father false==> è una creazione task ==> navighiamo su quel task
        //localToast?.navigate true && localToast?.navigate2father true ==> è sicureamente un esitazione ==> navighiamo sul padre
        let localNavigate = true; //to avoid the effect task reload on itself
        setTimeout(() => {    
       
            let idLink = 'child';//localToast?.taskId;  //we are working on this link
            if (localToast?.idTaskPadre !== undefined && localToast?.idTaskPadre !== "" && localToast?.navigate && localToast?.navigate2father ) {
                //if you came from an esitazione then u can go to father
                //idLink = localToast?.idTaskPadre; 
                idLink= 'father';
                //if you are here u are closing task and u are going to father
            } else if (localToast?.navigate && localToast?.navigate2father) { 
                //siamo in esitazione di un task padre, scegliamo di non visualizzarlo
                localNavigate=false;
            } 
            // determina se esiste un task in carico al FE
        
            //go to link selected new child or father
            if (localToast?.navigate && localNavigate && document.getElementById(idLink)!==null)/*&& !(taskIdInCharge.toString().includes(idLink)))*/
                document.getElementById(idLink).click();
            if (JSON.parse(requestIdFromProps).operation.status==='SUCCESS')
                dispatch(removeTotalItemMessageToast(localToast?.requestId));
        }, timeout);
            
    }, [props]);

    return (   
        <>
            <h5 className="fw-bold">
                Aggiornamento: <span className="text-lowercase">{localToast?.entityName}</span>
            </h5>
            <p>
            {`${JSON.parse(requestIdFromProps).operation.status}  `}
            { (localToast?.localErr?.message) ? `${localToast?.localErr?.message}` : null}
            </p>
            {
                (localToast?.idTaskPadre !== undefined && localToast?.idTaskPadre !== "" && localToast?.navigate && localToast?.navigate2father)  ?
                <Link id='father'  to={`/task-${localToast?.idTaskPadre}`}  onClick={() => handleAddTaskTab(localToast?.idTaskPadre)} className="btn btn-primary btn-sm">
                 Task:_{localToast?.idTaskPadre}
                </Link> :
                 <Link id='child'  to={`/task-${localToast?.taskId}`}  onClick={() => handleAddTaskTab(localToast?.taskId)} className="btn btn-primary btn-sm">
                 Task:_{localToast?.taskId}
             </Link> 


            }  
            
            {/* <Link id={localToast?.taskId}  to={`/task-${localToast?.taskId}`}  onClick={handleAddTaskTab} className="btn btn-primary btn-sm">
                Task:_{localToast?.taskId}
            </Link> a  */}

            <br/>
        </> 
    )
  }
  
  export default ToastContent;
  