/* eslint-disable prefer-object-spread */
import { createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../../data/axiosInstance/axiosInstance';
import executor from '../../../data/axiosInstance/executor';

const API_URL = `${process.env.REACT_APP_API_SINISTRI_URL}`;

const initialState = {
  loaderCatastrofali: false,
  loaderGrigliaSinistri: false,
  listaEventiCatastrofali: [],
  singleEventoCatastrofali: {},
  listaSinitsriCatastrofali: [],
};

export const EventiCatastrofaliSlice = createSlice({
  name: 'eventiCatastrofali',
  initialState,
  reducers: {
    setLoaderCatastrofali: (state, action) => {
      state.loaderCatastrofali = action.payload;
    },
    setLoaderGrigliaSinistri: (state, action) => {
      state.loaderGrigliaSinistri = action.payload;
    },
    getListEvents: (state, action) => {
      state.listaEventiCatastrofali = action.payload;
    },
    setListaSinistriCatastrofali: (state, action) => {
      state.listaSinitsriCatastrofali = action.payload;
    },
    setSingleEventoCatastrofali: (state, action) => {
      state.singleEventoCatastrofali = action.payload;
    },
  },
});

export const {
  setLoaderCatastrofali,
  setLoaderGrigliaSinistri,
  getListEvents,
  setListaSinistriCatastrofali,
  setSingleEventoCatastrofali,
} = EventiCatastrofaliSlice.actions;

export const fetchListaEventiCatastrofali = () => async (dispatch) => {
  dispatch(getListEvents([]));
  dispatch(setLoaderCatastrofali(true));
  const API_URI_CALL = `${API_URL}/api/v1/bff/eventoCatastrofale/lista-all`;

  const fetchData = async () => axiosInstance.get(API_URI_CALL);

  const [res, err] = await executor.executeAsync(fetchData);

  console.log('res', res);
  console.log('err', err);
  if (err) return true;

  dispatch(setLoaderCatastrofali(false));
  return dispatch(getListEvents(res.data));
};

export const fetchSingleEventById = (id) => async (dispatch) => {
  dispatch(setLoaderCatastrofali(true));
  const API_URI_CALL = `${API_URL}/api/v1/bff/eventoCatastrofale/${id}`;

  const fetchData = async () => axiosInstance.get(API_URI_CALL);

  const [res, err] = await executor.executeAsync(fetchData);

  console.log('res', res);
  console.log('err', err);
  if (err) return true;

  dispatch(setLoaderCatastrofali(false));
  return dispatch(setSingleEventoCatastrofali(res.data));
};

export const fetchListaSinistriCatastrofali = (idEvento, isCodiceLob) => async (dispatch) => {  
  dispatch(setLoaderGrigliaSinistri(true));
  dispatch(setListaSinistriCatastrofali([]));

  if(idEvento !== null && idEvento !== undefined && idEvento !== ''){
    const API_URI_CALL = `${API_URL}/api/v1/bff/denunce/sinitri/eventoCatastrofale/${idEvento}/lob/${isCodiceLob}`;
    const fetchData = async () => axiosInstance.get(API_URI_CALL);
    const [res, err] = await executor.executeAsync(fetchData);
    
    if (err){
      console.log('err', err);
      dispatch(setLoaderGrigliaSinistri(false));
      return true;
    } 
    if(res){
      console.log('res', res);
      dispatch(setLoaderGrigliaSinistri(false));
      return dispatch(setListaSinistriCatastrofali(res.data));
    }
    
  }
 
  return dispatch(setLoaderGrigliaSinistri(false));
};

export default EventiCatastrofaliSlice.reducer;
