const TIPOLOGIERECUPERI = {
  UF_SIN_RAMO_CAUZIONI: 'Ufficio sinistri ramo cauzioni',
  UF_SIN_RAMI_ELEMENTARI: 'Ufficio sinistri rami elementari',
  UF_SIN_RISCHI_AGRICOLO: 'Ufficio sinistri rischi agricolo',
  UF_SIN_MARINE: 'Ufficio sinistri Marine',
  UF_SIN_SPECIALTIES: 'Ufficio sinistri Specialties',
}

const ISPETTORATO = {
  ASSICURATO: 'Assicurato',
  TERZO: 'Terzo'
}
const ESITI = {
  SINISTRO_APERTO: 'Sinistro Aperto',
  SINISTRO_CHIUSO: 'Sinistro chiuso',
  IN_AGENDA: 'In agenda',
  SEGNA_DUPLICATO: 'Segna come duplicato',
  CAUSA_ATTESA_INCARICO: 'Causa in attesa incarico',
  CAUSA_ATTESA_UDIENZA : 'Causa in attesa udienza',
  DINIDEGO_RIFIUTATO_INTEGRAZIONE: 'Diniego rifiutato con integrazione',
  CALENDAR: 'Calendar',
  SHELL_CLAIM: 'Shell Claim',
  DENUNCIA_RIATTIVATA: 'Denuncia riattivata',
  SINISTRO_APERTO_DA_SHELL_CLAIM: 'Sinistro Aperto Shell',
  SUPERAMENTO_AUTHORITY_APPROVATO: 'Superamento authority approvato',
  SUPERAMENTO_AUTHORITY_RIFIUTATO: 'Superamento authority rifiutato',
  VAL_PAGAMENTO_ACCETTATA: 'Validazione pagamento accettata',
  VAL_PAGAMENTO_RIFIUTATA: 'Validazione pagamento rifiutata',
  RICHIESTA_AUTORIZZAZIONE: 'Richiesta autorizzazione',
  FIDUCIARIO_NOMINATO: 'Fiduciario nominato',
  INCARICO_COMPLETATO: 'Incarico completato',
  INCARICO_ANNULLATO: 'Incarico annullato',
  CAUSA_APERTA: 'Causa aperta',
  CAUSA_CHIUSA: 'Causa chiusa',
  INVIO_ORDINE_PAGAMENTO: 'Invio ordine di pagamento',
  QUIETANZA_EMESSA: 'Quietanza emessa',
  QUIETANZA_EMESSA_AGENDA: 'Quietanza emessa in agenda',
  QUIETANZA_ANNULLATA : 'Annullata',
  PAGAMENTO_INDENNIZZO_NON_ACCETTATO: 'Pagamento indennizzo non accettato',
  PAGAMENTO_INDENNIZZO_AGENDA: 'Pagamento indennizzo in agenda',
  PAGAMENTO_SPESE_AGENDA: 'Pagamento spese in agenda',
  INCARICO_AGENDA: 'Incarico in agenda',
  RICHIESTA_INTEGRAZIONE_AGENDA: 'Richiesta integrazione agenda',
  PAGAMENTO_ANNULLATO: 'Pagamento annullato',
  QUIETANZA_NON_ACCETTATA: 'Quietanza non accettata',
  PAGAMENTO_EFFETTUATO: 'Pagamento effettuato',
  CHIUSURA_INCARICO: 'Chiusura incarico',
  RIATTIVAZIONE_INCARICO: 'Riattivazione incarico',
  CAUSA_ANNULLATA: 'Causa annullata',
  APERTURA_RECUPERO: 'Apertura recupero',
  RIAPERTURA_RECUPERO: 'Riapertura recupero',
  CHIUSURA_RECUPERO: 'Chiusura recupero',
  INCASSO_RECUPERO: 'Incasso recupero',
  RECUPERO_ANNULLATO: 'Recupero annullato',
  IN_VALIDAZIONE: 'In validazione',
  SENZA_SEGUITO: 'Senza seguito',
  SINISTRO_RIAPERTO: 'Sinistro riaperto',
  SINISTRO_IN_LAVORAZIONE: 'Sinistro in lavorazione',
  SINISTRO_PARZIALE: 'Sinistro parziale',
  SINISTRO_CHIUSO_TOTALE: 'Sinistro chiuso totale',
  SINISTRO_COMPLETATO: 'Sinistro Completato',
  RIASSEGNA_SINISTRO: 'Riassegna sinistro'
}
/* Sinistro parziale
Sinistro chiuso totale */

const TIPOPAGAMENTO = {
  LQIND: 'LQIND',
  LQSPE: 'LQSPE',
}

const EnumService = {
  TIPOLOGIERECUPERI,
  ISPETTORATO,
  ESITI,
  TIPOPAGAMENTO
}

export default EnumService;