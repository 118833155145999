import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  Button,
  Label,
  FormGroup,
  Container,
  Row,
  Col,
  Card,
  CardBody
} from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { axiosInstanceService } from '../../../data/axiosInstance/axiosInstance';
import logoLogin from '../../../assets/images/logos/logo_revo_login.svg';
import AuthService from "../../jwt/auth.service";
import useMounted from "../../authGurad/useMounted";
import { fetchUserData } from '../../../store/app/ticket/TicketSlice';
import './FormLogin.css';
import FormLoginSSO from './FormLoginSSO';

const formSSOEnabled = `${process.env.REACT_APP_SSO_LOGIN_ENABLED}` === 'true';

const FormLogin = () => {
  const mounted = useMounted();
  const dispatch = useDispatch();

  const initialValues = {
    email: '',
    password: '',
    submit: null,
  };
  
  const navigate = useNavigate();
  
  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Email is invalid').required('Email is required'),
    password: Yup.string()
    .min(6, 'Password must be at least 6 characters')
    .required('Password is required'),
  });
  
  let message = localStorage.getItem("message");
  if(!message){
    message="";
  }
  if (message.toString() !== "") {
    message = "Username e/o Password non sono corretti. Controllare le credenziali.";
    localStorage.setItem("message", "");
  }
  return (
    (formSSOEnabled) ?
      <FormLoginSSO />
    :
      <div id="loginContainer" className="loginBox">
        <Container fluid className="h-100">
          <Row className="justify-content-center align-items-center h-100">
            <Col lg="12" className="loginContainer">
              <Card>
                <CardBody className="p-4 m-1">
                  <img alt="Logo Login" src={logoLogin} /> {/*className="d-block mx-auto mb-4"*/}

                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                      try {
                        //await signInWithEmailAndPassword(values.email, values.password);
                        AuthService.login(values.email, values.password).then(
                          (responsed) => {
                            localStorage.setItem("user", JSON.stringify(responsed));
                              axiosInstanceService.setToken();
                            if (responsed.access_token) {
                              dispatch(fetchUserData(values?.email?.toLowerCase()));
                              navigate("/")
                            } else {
                              localStorage.setItem("message", "400");
                              window.location.reload();
                            }
                          }
                        );

                        //navigate('/auth/Register');
                        if (mounted.current) {
                          setStatus({ success: true });
                          setSubmitting(true);
                        }
                      } catch (err) {
                        if (mounted.current) {
                          setStatus({ success: false });
                          setErrors({ submit: err.message });
                          setSubmitting(false);
                        }
                      }
                    }}
                  >
                    {({ errors, touched, handleSubmit, handleChange, values }) => (
                      <Form onSubmit={handleSubmit}>
                        <div className="mt-2">
                          <Col lg="8" className='mx-auto'>
                            <FormGroup>
                              <Label htmlFor="email">Email</Label>
                              <Field
                                name="email"
                                type="text"
                                value={values.email}
                                placeholder="demo@demo.com"
                                onChange={handleChange}
                                className={`form-control${errors.email && touched.email ? ' is-invalid' : ''
                                  }`}
                              />
                              <ErrorMessage name="email" component="div" className="invalid-feedback" />
                            </FormGroup>
                            <FormGroup>
                              <Label htmlFor="password">Password</Label>
                              <Field
                                name="password"
                                type="password"
                                placeholder="demo123"
                                value={values.password}
                                onChange={handleChange}
                                className={`form-control${errors.password && touched.password ? ' is-invalid' : ''
                                  }`}
                              />
                              <ErrorMessage
                                name="password"
                                component="div"
                                className="invalid-feedback"
                              />

                            </FormGroup>
                          </Col>
                          <div className="mx-auto col-lg-7 mb-3 text-danger">{message}</div>


                        </div>

                        <div className="mt-4 d-grid">
                          <Button
                            type="submit"
                            color="primary"
                            className="text-uppercase mx-auto col-auto mb-4"
                          >
                            Login
                          </Button>
                          {/* <Link
                            to="/auth/register"
                            className="link-primary mb-4 mx-auto d-lock col-auto"
                          >
                            Hai dimenticato la password?{' '}
                          </Link> */}
                        </div>
                      </Form>
                    )}
                  </Formik>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
  );
};

export default FormLogin;
