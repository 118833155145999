/* eslint-disable prefer-object-spread */
import { createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../../data/axiosInstance/axiosInstance'; 
import executor from   '../../../data/axiosInstance/executor';

const API_URL = `${process.env.REACT_APP_API_SINISTRI_URL}`;
 
const initialState ={
  tipologia:[],
  canale:[],
};


export const FormTipologieSlice = createSlice({
  name: 'tipologie',
  initialState,
  reducers: {
    getTipologia: (state, action) => {
      state.tipologie = action.payload;
    },
    getTipologiaCanale: (state, action) => {
      state.canale = action.payload.canale;
      state.tipologia = action.payload.tipologia;
    }
  }
});

export const { getTipologia, getTipologiaCanale } = FormTipologieSlice.actions;

export const fetchFormTipologia = () => async (dispatch) => {

  try {
    const API_URI_CALL = `${API_URL}/api/v1/bff/task/tipologie`;
    const fetchData = async () => axiosInstance.get(API_URI_CALL);
  
    const [res, err] = await executor.executeAsync(fetchData);
  
    console.log('err',err);  
    if(err){
      // dispatch(fetchFormTipologia());
      return dispatch(getTipologia(initialState));
    }
    return dispatch(getTipologia(res.data));
  } catch (error) {
     console.log('err',error);  
     return dispatch(getTipologia(initialState));
  }
};

export const fetchFormTipologiaCanale = () => async (dispatch) => {

  try {
    const API_URI_CALL = `${API_URL}/api/v1/bff/denunce/lista/canali`;
    const fetchData = async () => axiosInstance.get(API_URI_CALL);
  
    const [res, err] = await executor.executeAsync(fetchData);
  
    console.log('err',err);  
    if(err){
      // dispatch(fetchFormTipologia());
      return dispatch(getTipologiaCanale(initialState));
    }
    return dispatch(getTipologiaCanale(res.data));
  } catch (error) {
     console.log('err',error);  
     return dispatch(getTipologiaCanale(initialState));
  }
};

export default FormTipologieSlice.reducer;
