// Funzione per ottenere la data per la chiamata al servizio catastrofali
function toISOStringWithTimeZone(date) {
  const pad = (num) => (num < 10 ? '0' : '') + num;

  const year = date.getFullYear();
  const month = pad(date.getMonth() + 1); // I mesi partono da 0, quindi aggiungiamo 1
  const day = pad(date.getDate());
  const hours = pad(date.getHours());
  const minutes = pad(date.getMinutes());
  const seconds = pad(date.getSeconds());

  /* const timezoneOffset = -date.getTimezoneOffset();
  const sign = timezoneOffset >= 0 ? '+' : '-';
  const timezoneHours = pad(Math.floor(Math.abs(timezoneOffset) / 60));
  const timezoneMinutes = pad(Math.abs(timezoneOffset) % 60); */

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
}

// Funzione per ottenere la data formattata in ISO con "Z" senza conversione UTC
function toFakeUTCISOString(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() ritorna 0-11, quindi aggiungiamo 1
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  // Costruisco la stringa in formato ISO ma senza conversione a UTC, aggiungendo manualmente 'Z'
  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.000Z`;
}

// Funzione per ottenere la data formattata in ISO con "Z" con conversione UTC
function formatDateISO(dateString) {
  // Creo un oggetto Date dalla stringa ISO (2024-01-01T04:00:00.000Z)
  const date = new Date(dateString);
  
  // Ottiengo i componenti della data
  const day = String(date.getUTCDate()).padStart(2, '0');
  const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // I mesi in JavaScript vanno da 0 a 11, quindi aggiungi 1
  const year = date.getUTCFullYear();
  
  // Ottiengo i componenti del tempo
  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');
  const seconds = String(date.getUTCSeconds()).padStart(2, '0');
  
  // Formatto la stringa come "DD-MM-YYYY HH:mm:ss"
  return new Date(`${year}-${month}-${day}T${hours}:${minutes}:${seconds}.000`);
}

const dateUtils = {
  formatDateISO,
  toISOStringWithTimeZone,
  toFakeUTCISOString
}

export default dateUtils;