import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  codeVerifier: localStorage.getItem("codeVerifier") || "",
  codeChallenge: localStorage.getItem("codeChallenge") || "",
};

export const PkceSlice = createSlice({
  name: "pkce",
  initialState,
  reducers: {
    setCodeVerifier: (state, action) => {
      state.codeVerifier = action.payload;
      localStorage.setItem("codeVerifier", action.payload);
    },
    setCodeChallenge: (state, action) => {
      state.codeChallenge = action.payload;
      localStorage.setItem("codeChallenge", action.payload);
    },
  },
});

export const { setCodeVerifier, setCodeChallenge } = PkceSlice.actions;



async function generateCodeChallenge(codeVerifier) {
  // Create a sha256 hash of the codeVerifier
  const textAsBuffer = new TextEncoder().encode(codeVerifier);
  const hashBuffer = await window.crypto.subtle.digest("SHA-256", textAsBuffer);
  const hashArray = Array.from(new Uint8Array(hashBuffer));

  // Convert the hash to a base64 string
  const base64 = btoa(String.fromCharCode.apply(null, hashArray));

  // Convert the base64 string to base64url
  const base64url = base64
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=/g, "");

  return base64url;
}

// Generate a secure random string using the browser crypto API
export const generateCodeVerifier = () => (dispatch) => {
  const array = new Uint32Array(28);
  window.crypto.getRandomValues(array);
  const value = Array.from(array, (dec) =>
    (`0${dec.toString(16)}`).slice(-2)
  ).join("");

  dispatch(setCodeVerifier(value));
  generateCodeChallenge(value).then((challenge) => {
    dispatch(setCodeChallenge(challenge));
  });
};

export default PkceSlice.reducer;