import axios from "axios";

const API_URL = `${process.env.REACT_APP_API_OVERX_URL}/api/v1/sicurezza/`;

const login = async (code, codeVerifier) => {
  try {
    const response = await axios
      .post(`${API_URL}login-code`, {
        code,
        redirectUri: `${window.location.origin}/home`,
        codeVerifier,
      });
    if (response.data.access_token) {
      localStorage.setItem("user", JSON.stringify(response.data));
      localStorage.removeItem("codeVerifier");
      localStorage.removeItem("codeChallenge");
      localStorage.setItem("refreshToken", response.data.refresh_token);
    }
    return response.data;
  } catch (e) {
    console.log("Err on loginAuth ", e);
    return e;
  }
};


const logout = async () => { // this is shit
  try{
    const response = await axios
    .post(`${API_URL}logout`, {
      "refresh_token": localStorage.getItem('refreshToken')
    });
    if(response.status === 204){
      localStorage.removeItem("user");
      localStorage.setItem("message", "");
      window.location.reload();
    }
    return response.data;
  }catch(e){
    console.log(e)
    return e
  }
};

 const AuthServiceSSO = {
  login,
  logout
};

export default AuthServiceSSO;
