/* eslint-disable prefer-object-spread */
import { createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../../data/axiosInstance/axiosInstance'; 
import executor from   '../../../data/axiosInstance/executor';

const API_URL = `${process.env.REACT_APP_API_SINISTRI_URL}`;

const initialState = {
  events: []
};

export const EventSlice = createSlice({
  name: 'event',
  initialState,
  reducers: {
    getEvents: (state, action) => {
      state.events = [];
      state.events = action.payload;
    }
  }
});

export const { getEvents } = EventSlice.actions;

/*
const currentYear = new Date().getFullYear();
const parseAdjust = (eventDate) => {
  const date = new Date(eventDate);
  date.setFullYear(currentYear);
  return date;
};*/
 

export const fetchEvents = () => async (dispatch) => {
  dispatch(getEvents([]));

  const API_URI_CALL = `${API_URL}/api/v1/bff/task/lista/agenda`;

  const fetchData = async  () => axiosInstance.get(API_URI_CALL);
  
  const [res, err] = await executor.executeAsync(fetchData);

  console.log('res',res); 
  console.log('err',err);
  if (err) return true;

  return dispatch(getEvents(res.data));   
}; 

export default EventSlice.reducer;
