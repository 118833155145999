import { lazy } from "react";
import { Navigate } from "react-router-dom";
import Loadable from "../layouts/loader/Loadable";
import AuthGuard from "../components/authGurad/AuthGuard";


/****Layouts*****/
const HomeLayout = Loadable(lazy(() => import("../layouts/HomeLayout")));
const BlankLayout = Loadable(lazy(() => import("../layouts/BlankLayout")));
//***** Pages ****/
const GridTask = Loadable(lazy(() => import("../views/GridTask")));
const DetailTask = Loadable(lazy(() => import("../views/dettaglio-task/DetailTask")));
const Ws = Loadable(lazy(() => import("../views/Ws")));
/***** Auth Pages ****/
/***** Auth Pages ****/
const OnError = Loadable(lazy(() => import("../views/auth/Error")));
const Register = Loadable(lazy(() => import('../views/auth/Register')));
const FormLogin = Loadable(lazy(() => import('../components/forms/form_login/FormLogin')));

/*****Routes******/
const ThemeRoutes = [
  {
    path: "/",
    element: (
      <AuthGuard>
        <HomeLayout />
      </AuthGuard>
    ),
    children: [
      { path: "/", element: <Navigate to="/home" /> },
      { path: "/home", exact: true, element: <GridTask /> },
      { path: `/task-:id`, exact: true, element: <DetailTask idTask="/task-:id"/> },
      { path: "*", element: <Navigate to="/auth/404" /> },
    ],
  },
  {
    path: "/auth",
    element: <BlankLayout />,
    children: [
      { path: '404', element: <OnError /> },
      { path: 'register', element: <Register /> },
      { path: 'login', element: <FormLogin /> },
      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
  {
    path: "/ws",
    element: <BlankLayout />,
    children: [
      { path: "ws-:id", exact: true, element: <Ws id=":id" /> }
    ],
  },
];

export default ThemeRoutes;
